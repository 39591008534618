@keyframes fade-in {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@keyframes fade-out {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

@keyframes submit-btn-sent {
    100%{
        transform: rotate(5deg);
        transform: rotate(-10deg);
    }
}

.appear{
    animation: 0.7s ease 0s 1 fade-in;
}

.disappear{
    animation-name: fade-out;
    animation-duration: 0.7s;
    animation-fill-mode: both;
}