html {
    scroll-behavior: smooth !important;
    scroll-padding: 5rem !important;
}

body {
    height: 100% !important;
    font-weight: 800 !important;
}

body ::selection {
    background-color: #D5873F;
    color: #2F292A;
}

.initial {
    opacity: 0;
    pointer-events: none;
    cursor: default;
}

.selected {
    color: #E4AD77 !important;
    border-bottom-width: 1px !important;
    border-bottom-color: #E4AD77 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.disappear {
    pointer-events: none;
    cursor: default;
}

.selected:hover,
.nav-btn:active,
.selected:active,
.hamburger-btn:hover {
    background-color: #494041 !important;
}

.nav-btn:hover {
    background-color: #494041 !important;
    color: #E4AD77 !important;
    border-bottom-width: 1px !important;
    border-bottom-color: #E4AD77 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-link {
    text-decoration: none !important;
}

.btn-group:hover {
    background-color: #D5873F !important;
    color: #2F292A;
    transform: scale(1.1);
}

.bs {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px;
}

.roomBg {
    background-color: rgba(247, 247, 247, 0.7);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 0 0 8px 8px;
}

.nav-bar {
    width: 100%;
}

.top {
    transition: 1s;
    height: 17vh;
    background-color: #2f292a95;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
}

.scroll {
    transition: 1s;
    height: 9vh;
    background-color: #2F292A;
}

.logo {
    background-color: #2f292a97;
    border-radius: 0 0 10px 0;
}

.bgDark {
    background-color: #2f292a57;
}

.starOff {
    color: #494041
}

.starOn {
    color: #D5873F
}

.registration {
    background: rgba(65, 59, 60, 0.716);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    color: #F7F7F7;
}

.registration input {
    background: #F7F7F7;
    color: #2F292A;
}

.textLines::before {
    content: " ";
    display: block;
    height: 2px;
    width: 90px;
    position: absolute;
    top: 50%;
    left: 120%;
    background: #D5873F;
}

.textLines::after {
    content: " ";
    display: block;
    height: 2px;
    width: 90px;
    position: absolute;
    top: 50%;
    right: 120%;
    background: #D5873F;
}

.textLines {
    position: relative;
}

@media screen and (max-width:350px) and (min-width:250px) {
    .datePicker {
        width: 32vh !important;
    }
}

@media screen and (max-width:250px) {
    .datePicker {
        width: 20vh !important;
    }
}

.datePicker {
    border-radius: 5px;
    text-align: center;
    font-weight: bolder;
    width: 40vh;
    height: 4vh;
}

#sidebar {
    height: 100vh !important;
}

.sortOpt {
    font-weight: bold;
}

#paymentBy {
    color: color-mix(in srgb, #D5873F, #F7F7F7) !important;
}